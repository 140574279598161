<template>
  <v-card id="card-modal-offer-trip" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text">
          {{ $t("components.views.trip.offers.client.modal_request.request") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" @click.stop="$emit('closeForm')" class="cont">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-0 pl-7">
      <DataTable
        :headers="headers"
        :url="url"
        :urlCount="urlCount"
        secondary
        :height="550"
        name="RequestTable"
        actionsOn
      >
        <template #[`item.needAdvance`]="{ item }">
          {{
            item.needAdvance
              ? $t("components.views.trip.offers.client.modal_request.table_request.yes")
              : $t("components.views.trip.offers.client.modal_request.table_request.no")
          }}
        </template>
        <template #[`item.amount`]="{ item }">
          {{ formatMoney(item.amount, item.currency) }}
        </template>
        <template #[`item.options`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                text
                v-bind="attrs"
                v-on="on"
                @click="confirmRequest(item)"
              >
                <v-icon small> fa-solid fa-check </v-icon>
              </v-btn>
            </template>
            <span>{{
              $t("components.views.trip.offers.client.modal_request.table_request.accept")
            }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                text
                color="red darken-2"
                v-on="on"
                @click="rejectRequest(item)"
              >
                <v-icon small> fa-solid fa-xmark </v-icon>
              </v-btn>
            </template>
            <span>{{
              $t("components.views.trip.offers.client.modal_request.table_request.reject")
            }}</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t(
            "components.views.trip.offers.client.modal_request.table_request.company_transporter"
          ),
          class: "text-center",
          value: "transporterCompanyName",
        },
        {
          text: this.$t(
            "components.views.trip.offers.client.modal_request.table_request.license_plate"
          ),
          class: "text-center",
          value: "licensePlate",
        },
        {
          text: this.$t(
            "components.views.trip.offers.client.modal_request.table_request.driver_name"
          ),
          class: "text-center",
          value: "driverName",
          align: "text-capitalize",
        },
        {
          text: this.$t("components.views.trip.offers.client.modal_request.table_request.value"),
          class: "text-center",
          value: "amount",
        },
        {
          text: this.$t("components.views.trip.offers.client.modal_request.table_request.advance"),
          class: "text-center",
          value: "needAdvance",
          align: "center",
        },
        {
          text: this.$t(
            "components.views.trip.offers.client.modal_request.table_request.driver_phone"
          ),
          class: "text-center",
          value: "cellPhone",
          align: "text-capitalize",
        },
      ];
    },
    url() {
      return `/Offers/RequestList/${this.idMonitoringOrder}`;
    },
    urlCount() {
      return `/Offers/RequestListCount/${this.idMonitoringOrder}`;
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async rejectRequest(item) {
      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.offers.client.modal_request.reject_title_confirm"),
          this.$t("components.views.trip.offers.client.modal_request.reject_message_confirm"),
          "rechazar"
        )
      ) {
        this.showLoader({
          show: true,
        });

        let params, route;

        //Si es para Conductor
        if (this.type == 0) {
          params = {};
          route = `/Offers/Reject?idMonitoringOrder=${this.idMonitoringOrder}&idDriver=${item.idDriver}`;
        }

        //Si es para Empresa de Transporte
        if (this.type == 1) {
          params = {
            idMonitoringOrder: this.idMonitoringOrder,
            idTransporter: item.idTransporter,
          };

          route = `/Offers/CancelledTransportCompany`;
        }

        await this.axios
          .post(route, params)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.trip.offers.client.modal_request.reject_message_confirm_ok"),
              title: this.$t("components.views.trip.offers.client.modal_request.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
            this.$emit("updateOffers");
          });
      }
    },

    async confirmRequest(item) {
      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.offers.client.modal_request.accept_title_confirm"),
          this.$t("components.views.trip.offers.client.modal_request.accept_message_confirm"),
          "aceptar"
        )
      ) {
        this.showLoader({
          show: true,
        });

        let params, route;

        //Si es para Conductor
        if (this.type == 0) {
          params = {};
          route = `/Offers/Confirmed?idMonitoringOrder=${this.idMonitoringOrder}&idDriver=${item.idDriver}`;
        }

        //Si es para Empresa de Transporte
        if (this.type == 1) {
          params = {
            idMonitoringOrder: this.idMonitoringOrder,
            idTransporter: item.idTransporter,
            idDriver: item.idDriver,
          };

          route = `/Offers/ConfirmedTransportCompany`;
        }

        await this.axios
          .post(route, params)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.trip.offers.client.modal_request.accept_message_confirm_ok"),
              title: this.$t("components.views.trip.offers.client.modal_request.success"),
              name: "success",
            });
            this.$emit("updateOffers");
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
            this.$emit("updateOffers");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ModalRequestStyle.scss';

#card-modal-offer-trip {
  border: 3px solid #0b0045 !important;
}

.v-divider {
  border-width: 1px !important;
}

#card-modal-offer-trip p {
  font-size: 1.17em !important;
}
.cont {
  position: absolute;
  right: 10px;
}

.card-modaltitle {
    border-bottom: 1px solid #ccc;
}

</style>
